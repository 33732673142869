import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../services/api'

import './styles/Login.css';
import logo from '../imgs/logo.png';

const swal = withReactContent(Swal)

function ChangePw() {

    function login(e) {
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        const data = new FormData(form);

        if (data.get('login') === '') {
            swal.fire('Informe seu login!', 'Informe seu login', 'warning')
            return
        }

        if (data.get('senha') === '') {
            swal.fire('Informe a senha!', 'Informe a senha', 'warning')
            return
        }
        //Converte FormData em JSON
        var object = {};

        data.forEach(function (value, key) {
            object[key] = value;
        });

        //Checa no servidor
        api.post('usuarios/changePw', object)
            .then(function (response) {
                swal.fire('Senha cadastrada com sucesso!', '', 'success')
                    .then((result) => {
                        if (result.value) {
                            window.location.href = '/primavera/login'
                        }
                    })
            })
            .catch(function (error) {
                swal.fire('Senha não cadastrada! Usuário não encontrado!', '', "error")
            });
    }
    console.log(logo)

    return (
        <div className="Login">
            <div className="form">
                <img alt="fr_logo" src={logo} title="Floresta Real" className="logo" />
                <form className="form-signin" id="loginForm" onSubmit={login}>
                    <div className="form-label-group">
                        <input type="text" id="login" name="login" className="loginInput" placeholder="Usuário" />
                        <label htmlFor="login">Informe seu Login</label>
                    </div>
                    <div className="form-label-group">
                        <input type="password" id="senha" name="senha" className="loginInput" placeholder="Senha" />
                        <label htmlFor="senha">Informe a senha</label>
                    </div>
                    <button className="loginButton" type="submit" rel="nofollow">
                        <span><p className='loginButtonText'>Cadastrar Senha</p></span>
                    </button>

                </form>
            </div>
        </div>
    );
}

export default ChangePw;
