import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NavBar from "../components/NavBar";
import FrTextInput from "../components/FrTextInput";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import FrSelect from "../components/FrSelect";
import FrTable from '../components/FrTable'
import Dialog from '@material-ui/core/Dialog';
import { Icon } from "react-icons-kit";
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'

import api from "../services/api";
import { dateSql, getUserCode, paramsToObject } from "../utils/functions";

import "./styles/DefaultReg.css";
import FooterDelphus from "../components/FooterDelphus";

import FrDatePicker from '../components/FrDatePicker';
import Tabs from '../components/Tabs';
import TableModal from '../components/TableModal'
import FrTableReg from '../components/FrTableReg'
import FrameF2 from '../components/FrameF2'


const swal = withReactContent(Swal);

function Internacao() {
  const [data, setData] = useState({});
  const [updateType, setUpdateType] = useState(false);
  const [enableEdit, setEnableEdit] = useState("N");
  const [dataPmd, setDataPmd] = useState({})
  const [dataAdmissao, setDataAdmissao] = useState({})

  const [comboPac, setComboPac] = useState([{ value: "", display: "" }]);
  const [comboPho, setComboPho] = useState([{ value: "", display: "" }]);
  const [comboPlt, setComboPlt] = useState([{ value: "", display: "" }]);
  const [comboSti, setComboSti] = useState([{ value: "", display: "" }]);
  const [comboUnd, setComboUnd] = useState([{ value: "", display: "" }]);
  const [ana, setAna] = useState('')
  const [dataChk, setDataChk] = useState({})
  const [pac, setPac] = useState('')
  const [comboTpg, setComboTpg] = useState([{ value: "", display: "" }]);
  const [valor, setValor] = useState('')
  const [tph, setTph] = useState('')


  const [selected, setSelected] = React.useState([]);
  const [showModal, setShowModal] = useState(false)
  const [dataIcb, setDataIcb] = useState([{ value: "", display: "" }]);

  const [showModalTph, setShowModalTph] = useState(false)
  const [dataParcelas, setDataParcelas] = useState({})
  const [showModalParcelas, setShowModalParcelas] = useState(false)

  const columnsPmd = [
    { id: 'data_formatada', numeric: false, label: 'Data', align: 'left', minWidth: 40 },
    { id: 'nomeprf', numeric: false, label: 'Profissional', align: 'left', minWidth: 150 },

  ]

  const columnsAdm = [
    { id: 'formatdata_movimento', numeric: false, label: 'Data', align: 'left', minWidth: 60 },
    { id: 'nomeprf', numeric: false, label: 'Profissional', align: 'left', minWidth: 150 },
    { id: 'data_entrada_formatada', numeric: false, label: 'Data Entrada', align: 'left', minWidth: 40 },
    { id: 'data_saida_formatada', numeric: true, label: 'Data Saída', align: 'left', minWidth: 40 },
    { id: 'nomesti', numeric: false, label: 'Status', align: 'left', minWidth: 150 },

  ]


  const columnsChk = [
    { id: 'tipo', numeric: false, label: 'Tipo', align: 'left', minWidth: 60 },
    { id: 'formatdata', numeric: false, label: 'Data', align: 'left', minWidth: 60 },
    { id: 'nomeprf', numeric: false, label: 'Profissional', align: 'left', minWidth: 150 },
    { id: 'nomeusu', numeric: false, label: 'Usuário Lançamento', align: 'left', minWidth: 40 },
    { id: 'observacoes', numeric: true, label: 'Observações', align: 'left', minWidth: 40 },
  ]



  const columns = [
    { id: 'pk_icb', numeric: false, label: 'Nº Cobrança', align: 'left', minWidth: 200 },
    { id: 'nometic', numeric: false, label: 'Tipo', align: 'left', minWidth: 200 },
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 200 },
    { id: 'format_data_emissao', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 100, order: 'data_emissao' },
    { id: 'format_data_previsao_vcto', numeric: false, label: 'Previsão Vcto', align: 'left', minWidth: 100, order: 'data_previsao_vcto' },
    { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 200 },
    { id: 'format_valor', numeric: true, label: 'Valor', align: 'left', minWidth: 80 },

  ]

  const columnsParcelas = [
    { id: 'data', numeric: false, label: 'Vencimento', align: 'left', minWidth: 40, order: 'data_previsao_vcto' },
    { id: 'valor', numeric: false, label: 'Valor', align: 'left', minWidth: 40 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams);

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos("Internação", "Alteracao"));
      } else {
        setEnableEdit(await api.getAcessos("Internação", "Inclusao"));
      }

      setComboPac(await api.getComboData("pacientes"));
      setComboPho(await api.getComboData("plano_hospedagem"));
      setComboPlt(await api.getComboData('plano_trabalho'))
      setComboSti(await api.getComboData("status_internacao"));
      setComboUnd(await api.getComboDataWhere("unidade", "?fk_int=" + query.codigo));
      setComboTpg(await api.getComboData("tipo_pagamento"));

      if (query.codigo > 0) {
        setUpdateType(true);
        api.get("/internacao?pk_int=" + query.codigo).then(r => {
          if (r.data[0]) {
            if (!r.data[0].desconto_contratual)
              r.data[0].desconto_contratual = 0
            r.data[0].desconto_contratual = Number(r.data[0].desconto_contratual).toFixed(2).toString().replace(".", ",")
            setData(r.data[0]);
            setPac(r.data[0].fk_pac)

            api.get('/prescricao_medica?fk_int=' + query.codigo + '&ativo=S').then(r => {
              if (r.data) {
                setDataPmd(r.data);
              }
            })

            api.get('/anamnese?pk_int=' + query.codigo + '&fk_tna=1').then(r => {
              if (r.data) {
                setDataAdmissao(r.data);
              }
            })

            api.get('/checkin_out?fk_int=' + query.codigo).then(r => {
              if (r.data) {
                setDataChk(r.data);
              }
            })


            swal.close();
          } else {
            swal
              .fire(
                "Código inválido",
                "Código informado não encontrado!",
                "warning"
              )
              .then(result => {
                if (result.value) {
                  window.history.back();
                }
              });
          }
        });
      } else {
        if (query.fk_pac > 0) {
          const auxValues = { ...data };
          auxValues.fk_pac = query.fk_pac;
          setData(auxValues)

        }
        swal.close();
      }


    };
    fetchData();
  }, []);

  function consistData(data) {
    let required = [
      "data_entrada",
      "responsavel",
      "fk_pac",
      "fk_pho",
      'fk_plt',
      "fk_sti",
      "fk_und"
    ];

    let errors = [];

    required.forEach((curr, index) => {
      let fieldName = curr;
      if (curr === "responsavel") fieldName = "Responsável";
      if (curr === "fk_pac") fieldName = "Residente";
      if (curr === "fk_pho") fieldName = "Plano de Hospedagem";
      if (curr === "fk_sti") fieldName = "Status Internação";
      if (curr === "fk_plt") fieldName = "Plano de Trabalho";
      if (curr === "fk_und") fieldName = "Unidade";

      if (!data[curr]) return errors.push(fieldName);
    });


    if ((data['exige_data_saida'] === 'S') && (!data['data_saida'])) {
      errors.push('data_saida')
    }



    if ((!valor) && (!data.data_saida)) {
      errors.push('Valor não informado')
    }

    return errors;
  }

  function verCancelamentos(e) {
    e.preventDefault();

    if ((updateType) && (data.data_saida)) {
      api.get('/itens_cobranca?fk_pac=' + data.fk_pac + "&situacao= and fk_ctr is null and estornado<>'C'").then(r => {
        if (r.data[0]) {

          swal.fire({
            title: "ATENÇÃO",
            text: 'Residente tem Mensalidades em Aberto, deseja cancelar?',
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
          })
            .then(result => {
              if (result.value) {
                setDataIcb(r.data)
                setShowModal(true)
              } else
                handleSubmit(e)
            })
        } else
          handleSubmit(e)

      })
    } else {
      api.get('/internacao/simula_parcelas?fk_pho=' + data.fk_pho + '&fk_pac=' + data.fk_pac + '&data_prev_saida=' + data.data_prev_saida + '&desconto_parcelas_qtd=' + data.desconto_parcelas_qtd + '&dia_vcto=' + data.dia_vcto + '&fk_tpg=' + data.fk_tpg + '&fk_und=' + data.fk_und + '&desconto_contratual=' + data.desconto_contratual).then(r => {
        setDataParcelas(r.data)
        setShowModalParcelas(true)
      })
    }

  }


  function escondeModal(e) {
    e.preventDefault()
    setSelected([])
    setShowModal(false)
    setShowModalTph(false)
    setShowModalParcelas(false)

  }

  function confirmaModal(e) {
    e.preventDefault()
    setShowModal(false)
    handleSubmit(e)
  }

  function confirmaModalTph(e) {
    e.preventDefault()
    swal.fire({
      customClass: {
        container: 'my-swal'
      },

      title: "ATENÇÃO",
      text: 'Confirma alterar a tabela de preços de Plano de Hospedagem x Unidade x Tipo de Pagamento?',
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não"
    })
      .then(result => {
        if (result.value) {
          api.post("/plano_hospedagem/editTph?pk_tph=" + tph + '&valor=' + valor + '&user=' + getUserCode() + '&fk_pho=' + data.fk_pho + '&fk_und=' + data.fk_und + '&fk_tpg=' + data.fk_tpg).then(r => {
            if (r.status === 200) {
              swal.fire({
                customClass: {
                  container: 'my-swal'
                },

                title: "Registro Alterado",
                text: "O registro foi alterado com sucesso!",
                icon: "success"
              })
                .then(result => {
                  if (result.value) {
                    setShowModalTph(false)
                  }
                });
            } else {
              swal.fire({
                customClass: {
                  container: 'my-swal'
                },

                title: "Erro!",
                text: "O registro não foi alterado!",
                icon: "warning"
              });
            }
          });
        }
      })
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (1 == 1) {
      let texto = "Deseja salvar " + (updateType ? "as alterações" : "a inclusão") + " do registro?"
      // if (valor)
      // texto.concat(" Confirma o valor da internação -> " + valor + '?')

      swal.fire({
        customClass: {
          container: 'my-swal'
        },

        title: "ATENÇÃO",
        text: texto,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      })
        .then(result => {
          if (result.value) {
            const auxValues = {
              ...data
            };

            setShowModalParcelas(false)

            let errors = consistData(auxValues);
            if (auxValues.data_prev_saida)
              auxValues.data_prev_saida = dateSql(auxValues.data_prev_saida)
            if (auxValues.data_saida)
              auxValues.data_saida = dateSql(auxValues.data_saida)
            if (auxValues.data_entrada)
              auxValues.data_entrada = dateSql(auxValues.data_entrada)

            auxValues.selected = selected
            if (errors.length === 0) {
              api.get('/internacao?fk_pac=' + auxValues.fk_pac + '&codint=' + auxValues.pk_int).then(r => {
                if (r.data[0]) {
                  swal.fire({
                    title: 'Erro!',
                    text: "Já existe internacao ativa para este residente!",
                    icon: 'warning',
                  })
                } else {


                  if (updateType) {
                    api.post("/internacao/edit", auxValues).then(r => {
                      if (r.status === 200) {
                        swal
                          .fire({
                            title: "Registro Alterado",
                            text: "O registro foi alterado com sucesso!",
                            icon: "success"
                          })
                          .then(result => {
                            if (result.value) {
                              window.history.back();
                            }
                          });
                      } else {
                        swal.fire({
                          title: "Erro!",
                          text: "O registro não foi alterado!",
                          icon: "warning"
                        });
                      }
                    });
                  } else {
                    auxValues.fk_usu = getUserCode()
                    api.post("/internacao/add", auxValues).then(r => {
                      if (r.status === 200) {

                        swal
                          .fire({
                            title: "Registro Adicionado",
                            text: "O registro foi adicionado com sucesso!",
                            icon: "success"
                          })
                          .then(async result => {

                            //pergunta se quer gerar a agenda
                            const { value: formValues } = await swal.fire({
                              title: 'Deseja gerar a agenda para o residente?',
                              html:

                                <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start' }}>

                                  <FrDatePicker
                                    name="dataIni"
                                    id="dataIni"
                                    color="#528b46"
                                    label="Período"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 190 }}

                                  />

                                  <FrDatePicker
                                    name="dataFim"
                                    id="dataFim"
                                    color="#528b46"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 190 }}

                                  />


                                </div>,
                              focusConfirm: false,
                              preConfirm: () => {
                                return {
                                  dataIni: document.getElementById('dataIni').value,
                                  dataFim: document.getElementById('dataFim').value,
                                }
                              },
                              showCancelButton: true,
                              cancelButtonText: 'Cancelar',
                              confirmButtonText: 'OK',
                              confirmButtonColor: `#528b46`,
                              allowOutsideClick: false
                            })

                            if ((formValues) && (formValues['dataIni']) && (formValues['dataFim'])) {
                              swal.fire({
                                html: <FrLoading text="Gerando agenda..." />,
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                allowEscapeKey: false
                              })

                              await api.post('/agenda/gerarAgenda?dataIni=' + formValues['dataIni'] + '&dataFim=' + formValues['dataFim'] + '&fk_pac=' + data.fk_pac).then(r => {
                                swal.fire({
                                  // title: 'Erro!',
                                  text: "Agenda gerada com sucesso!",
                                  icon: 'success',
                                })
                              })

                            } else
                              if (formValues) {
                                swal.fire({
                                  title: 'Erro!',
                                  text: "Data inicial e data final devem ser preenchidas!",
                                  icon: 'warning',
                                })
                              }
                            window.history.back();

                          })
                      } else {
                        swal.fire({
                          title: "Erro!",
                          text: "O registro não foi adicionado!",
                          icon: "warning"
                        });
                      }
                    });


                  }
                }
              })
            } else {
              swal.fire({
                title: "Erro!",
                text: "Verifique o(s) campo(s) " + errors.join(","),
                icon: "warning"
              });
            }
          }
        });
    }
  }

  function handleCancel(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text:
          "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      })
      .then(result => {
        if (result.value) {
          window.history.back();
        }
      });
  }

  const handleChange = e => {
    const auxValues = {
      ...data
    };
    switch (e.target.name) {
      case 'fk_sti':
        api.get('/status_internacao?pk_sti=' + e.target.value).then(r => {
          auxValues['exige_data_saida'] = r.data[0].exige_data_saida;
        })
        auxValues[e.target.name] = e.target.value;
        break
      case 'fk_pac':
      case 'pac':
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        break

      case 'ana':
        setAna(e.target.value)

      case 'valor':
        setValor(e.target.value)

      default:
        auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  useEffect(() => {
    const fetchPac = async () => {
      const auxValues = { ...data };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setData(auxValues);
      }
    }
    fetchPac()
  }, [pac])


  useEffect(() => {
    const fetchValor = async () => {
      setValor(0)
      setTph(0)

      if ((data.fk_tpg) && (data.fk_pho) && (data.fk_und)) {
        api.get("/plano_hospedagem/tph?pk_pho=" + data.fk_pho + '&fk_und=' + data.fk_und + '&fk_tpg=' + data.fk_tpg).then(r => {
          if (r.data[0]) {
            setValor(r.data[0].valor)
            setTph(r.data[0].pk)

          }
        })
      }
    }
    fetchValor()
  }, [data.fk_tpg, data.fk_pho, data.fk_und])


  useEffect(() => {
    const fetchTpg = async () => {
      const auxValues = { ...data };

      if (data.fk_pho > 0) {
        await api.get('/plano_hospedagem?pk_pho=' + data.fk_pho).then(r => {
          if (r.data.length > 0) {
            // auxValues['fk_tpg'] = r.data[0].fk_tpg
            auxValues['tipo_cob'] = r.data[0].tipo_cob
          }
        })

        setData(auxValues);
      }
    }
    fetchTpg()
  }, [data.fk_pho])

  const handleClick = () => {
    setShowModalTph(true);
  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3> {updateType ? "Edição" : "Inclusão"} de Internação </h3>
          <UserOptions />
        </div>
        <form id="regForm" onSubmit={verCancelamentos}>

          <Tabs aba1_caption="DADOS DA INTERNAÇÃO" aba1_hidden={false} Aba1={<div>

            <div class="container-fluid">
              <div class="row" >

                <div class="column">

                  <FrTextInput
                    maxLength='150'
                    value={pac}
                    onChange={handleChange}
                    name='pac'
                    id='pac'
                    color='#c0c0c0'
                    label='Residente'
                    variant='outlined'
                    size='small'
                    style={{ width: 80 }}
                  />

                  <FrSelect
                    value={data.fk_pac || ''}
                    onChange={handleChange}
                    name="fk_pac"
                    id="fk_pac"
                    data={comboPac}
                    style={{ width: 450 }}
                    required
                  />
                </div>

                <div class="column">
                  <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2>
                </div>


              </div>
            </div>

            <FrTextInput
              maxLength='150'
              value={data.responsavel || ''}
              onChange={handleChange}
              name="responsavel"
              id="responsavel"
              color="#528b46"
              label="Responsável pela Internação"
              variant="outlined"
              size="small"
              style={{ width: 350 }}
            />

            <FrSelect
              value={data.fk_und || ""}
              onChange={handleChange}
              name="fk_und"
              id="fk_und"
              label="Unidade"
              data={comboUnd}
              style={{
                width: 150
              }}
            />

            <br />
            <FrDatePicker
              value={(data.data_entrada || "").split("T")[0]}
              onChange={handleChange}
              name="data_entrada"
              id="data_entrada"
              color="#528b46"
              label="Entrada"
              variant="outlined"
              size="small"
              style={{ width: 190 }}
            />
            <FrDatePicker
              value={(data.data_prev_saida || "").split("T")[0]}
              onChange={handleChange}
              name="data_prev_saida"
              id="data_prev_saida"
              color="#528b46"
              label="Previsão Saída"
              variant="outlined"
              size="small"
              style={{ width: 190 }}
            />
            <FrDatePicker
              value={(data.data_saida || "").split("T")[0]}
              onChange={handleChange}
              name="data_saida"
              id="data_saida"
              color="#528b46"
              label="Saída"
              variant="outlined"
              size="small"
              style={{ width: 190 }}
            />
            <br />


            <FrSelect
              value={data.fk_plt || ""}
              onChange={handleChange}
              name="fk_plt"
              id="fk_plt"
              label="Plano de Trabalho"
              data={comboPlt}
              style={{ width: 200 }}
            />
            <FrSelect
              value={data.fk_sti || ""}
              onChange={handleChange}
              name="fk_sti"
              id="fk_sti"
              label="Status"
              data={comboSti}
              style={{ width: 150 }}
            />

            <br />
            <FrSelect
              value={data.fk_pho || ""}
              onChange={handleChange}
              name="fk_pho"
              id="fk_pho"
              label="Plano de Hospedagem"
              data={comboPho}
              style={{ width: 200 }}
            />

            <FrSelect
              value={data.fk_tpg || ''}
              onChange={handleChange}
              name="fk_tpg"
              id="fk_tpg"
              label='Tipo de Pagamento'
              data={comboTpg}
              style={{ width: 200 }}
            />

            <FrTextInput
              value={valor || ''}
              name="valor"
              id="valor"
              onChange={handleChange}
              color="#528b46"
              label="Valor"
              variant="outlined"
              size="small"
              style={{ width: 100 }}
              disabled
              endAdornment={
                <div style={{ color: '#528b46', cursor: 'pointer' }}

                  onClick={(e) => {
                    handleClick();
                  }}

                >
                  <Icon icon={ic_mode_edit} size={18} />
                </div>
              }

            />

            <FrTextInput
              maxLength='10'
              value={data.dia_vcto || ''}
              onChange={handleChange}
              name="dia_vcto"
              id="dia_vcto"
              color="#528b46"
              label="Dias vcto (separar por /)"
              variant="outlined"
              size="small"
              style={{ width: 170 }}
              hidden={data.tipo_cob !== 'Q'}
            />

            <FrTextInput
              maxLength='10'
              value={data.desconto_contratual || ''}
              onChange={handleChange}
              name="desconto_contratual"
              id="desconto_contratual"
              color="#528b46"
              label="% Desconto"
              variant="outlined"
              size="small"
              style={{ width: 130 }}
              hidden={data.tipo_cob !== 'M'}
            />

            <FrTextInput
              maxLength='10'
              value={data.desconto_parcelas_qtd || ''}
              onChange={handleChange}
              name="desconto_parcelas_qtd"
              id="desconto_parcelas_qtd"
              color="#528b46"
              label="Qtd.Parcelas p/Desconto"
              variant="outlined"
              size="small"
              style={{ width: 170 }}
              hidden={(data.tipo_cob !== 'M')}
            />
            <br />

            <FrTextInput
              maxLength="500"
              value={data.observacoes || ""}
              onChange={handleChange}
              name="observacoes"
              id="observacoes"
              color="#528b46"
              label="Observações"
              variant="filled"
              size="small"
              required
              style={{ width: 500 }}
              multiline
              rows="8"
              rowsMax="8"
              fullWidth
            />

          </div>

          }

            aba2_caption="Admissão" aba2_hidden={!updateType} Aba2={

              <div className="tableDiv">
                <TableModal detail regName="Admissao" columns={columnsAdm} searched={false} codeName="pk_ana" page={'Admissao'} data={dataAdmissao} enableEdit={true} enableDelete={false} />
              </div>

            }
            aba3_caption="Anamnese" aba3_hidden={!updateType}
            aba4_caption="Check In / Check Out" aba4_hidden={!updateType} Aba4={
              <div className="tableDiv">
                <FrTableReg detail regName="CheckIn_Out" columns={columnsChk} searched={false} codeName="pk_chk" page={'CheckIn_Out'} data={dataChk} readonly={true} />
              </div>
            }

            aba5_caption="Coletas" aba5_hidden={!updateType}
            aba6_caption="Prescrições" aba6_hidden={!updateType}
            Aba6={

              <div className="tableDiv">
                <TableModal detail regName="Prescricao" columns={columnsPmd} searched={false} codeName="pk_pmd" page={'Prescricao'} data={dataPmd} enableEdit={true} enableDelete={false} />
              </div>

            }


          >
          </Tabs>

          <div >
            <Dialog aria-labelledby="simple-dialog-title" open={showModalTph} fullWidth={false} maxWidth={'lg'} >

              <div style={{ padding: '20px', paddingTop: '30px' }}>
                <h6>Edição de valor tabela de preços Plano Hospedagem x Unidade x Tipo de Pagamento</h6>
                <br />
                <FrSelect
                  value={data.fk_pho || ""}
                  onChange={handleChange}
                  name="fk_pho"
                  id="fk_pho"
                  label="Plano de Hospedagem"
                  data={comboPho}
                  style={{ width: 200 }}
                  disabled
                />

                <FrSelect
                  value={data.fk_tpg || ''}
                  onChange={handleChange}
                  name="fk_tpg"
                  id="fk_tpg"
                  label='Tipo de Pagamento'
                  data={comboTpg}
                  style={{ width: 200 }}
                  disabled
                />
                <FrSelect
                  value={data.fk_und || ''}
                  onChange={handleChange}
                  name="fk_und"
                  id="fk_und"
                  label='Unidade'
                  data={comboUnd}
                  style={{ width: 200 }}
                  disabled
                />

                <FrTextInput
                  value={valor || ''}
                  name="valor"
                  id="valor"
                  onChange={handleChange}
                  color="#528b46"
                  label="Valor"
                  variant="outlined"
                  size="small"
                  style={{ width: 100 }}
                />
              </div>
              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv" >
                  <button style={{ height: '40px' }} onClick={confirmaModalTph}> Confirmar </button>
                  <button style={{ height: '40px' }} onClick={escondeModal}> Sair </button>
                </div>
              </div>
            </Dialog>
          </div>



          <div >
            <Dialog aria-labelledby="simple-dialog-title" open={showModalParcelas} fullWidth={false} maxWidth={'lg'} >

              <div style={{ padding: '20px', paddingTop: '30px' }}>
                <h6>Valores que serão gerados</h6>
                <br />
                <FrTable rowsPerPage={15} startOrderBy={'data_previsao_vcto'} regName="" columns={columnsParcelas} searched={false} codeName="" page={''} data={dataParcelas} readOnly={true} />

              </div>
              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv" >
                  <button style={{ height: '40px' }} onClick={handleSubmit}> Confirmar </button>
                  <button style={{ height: '40px' }} onClick={escondeModal}> Sair </button>
                </div>
              </div>
            </Dialog>
          </div>


          <div >
            <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={false} maxWidth={'lg'} >

              <div style={{ padding: '20px', paddingTop: '40px' }}>
                <FrTable mostraCheck={true} regName="itens_cobranca" columns={columns} searched={false} codeName="pk_icb" page={'itens_cobranca'} data={dataIcb} enableEdit={'S'} enableDel={'N'} setSelected={setSelected} selected={selected} />
              </div>

              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv" >
                  <button style={{ height: '40px' }} onClick={confirmaModal}> Confirmar </button>
                  <button style={{ height: '40px' }} onClick={escondeModal}> Sair </button>
                </div>
              </div>
            </Dialog>
          </div>

        </form>
        <div className="regButtonsDiv">
          {enableEdit === "S" ? (
            <button onClick={verCancelamentos}> Salvar </button>
          ) : (
            <></>
          )}
          <button onClick={handleCancel}>
            {enableEdit === "S" ? "Cancelar" : "Fechar"}
          </button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Internacao;
