import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker';
import FrameF2 from '../components/FrameF2'

import api from '../services/api'
import { dateSql, paramsToObject, getUserCode } from '../utils/functions'
import { mMoeda } from '../utils/masks';

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'
import moment from 'moment'

const swal = withReactContent(Swal)

function EmissaoNotas() {
  const [data, setData] = useState({})
  const [enableEdit, setEnableEdit] = useState('S')
  const [comboTnf, setComboTnf] = useState([{ value: '', display: '' }])
  const [nomePac, setNomePac] = useState('')
  const [comboTom, setComboTom] = useState([{ value: '', display: '' }])
  const [tom, setTom] = useState('')
  const [erroVencimento, setErroVencimento] = useState(false)

  useEffect(() => {
    const fetchData = async () => {

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      api.get('/contas_receber/pendenteNotas?pks=' + query.pks).then(async r => {
        if (!r.data[0].valor) {
          swal.fire({
            title: 'Nenhum registro pendente de emissão de nota',
            text: "Nenhuma conta pendente de emissão de nota selecionada!",
            icon: 'warning',
          }).then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
        } else {
          if (r.data[0].pacs > 1) {
            swal.fire({
              title: 'Registros selecionados devem ser do mesmo residente',
              text: "Registros selecionados devem ser do mesmo residente!",
              icon: 'warning',
            }).then((result) => {
              if (result.value) {
                window.history.back()
              }
            })
          } else {

            swal.fire({
              html: <FrLoading text="Carregando..." />,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            setNomePac(r.data[0].nomepac)
            setComboTom(await api.getComboData('tomador'))
            setComboTnf(await api.getComboData('tipo_nota'))

            const auxValues = { ...data };
            auxValues['valor_bruto'] = mMoeda(r.data[0].valor.toFixed(2));
            auxValues['data_emissao'] = dateSql(new Date());
            auxValues['valor_liquido'] = mMoeda(r.data[0].valor.toFixed(2));


            setData(auxValues)


            swal.close()
          }
        }
      })

    }

    fetchData()
  }, [])


  function handleVencimento(e) {
    e.preventDefault();
    let hoje = new Date()
    hoje = moment(hoje).format('yyyy-MM-DD')
    let vencimento = moment(e.target.value).format('yyyy-MM-DD')
    if ((e.target.value) && (vencimento < hoje)) {
      swal.fire('Data Vencimento inválida', 'Data de vencimento não pode ser anterior a hoje!', 'warning')
      setErroVencimento(true)
    } else
      setErroVencimento(false)
  }

  useEffect(() => {
    const fetchData = () => {
      const auxValues = { ...data };
      let valorPis = 0
      let valorCofins = 0
      let valorIr = 0
      let valorCsll = 0
      let valorIss = 0

      try {
        valorPis = parseFloat(data.valor_pis.replace(',', '.'))
      } catch (error) {
        valorPis = 0
      }

      try {
        valorCofins = parseFloat(data.valor_cofins.replace(',', '.'))
      } catch (error) {
        valorCofins = 0
      }

      try {
        valorIr = parseFloat(data.valor_ir.replace(',', '.'))
      } catch (error) {
        valorIr = 0
      }

      try {
        valorCsll = parseFloat(data.valor_csll.replace(',', '.'))
      } catch (error) {
        valorCsll = 0
      }

      try {
        valorIss = parseFloat(data.valor_iss.replace(',', '.'))
      } catch (error) {
        valorIss = 0
      }

      let vBruto = data.valor_bruto

      try {
        vBruto = data.valor_bruto.replace('.', '')
        vBruto = vBruto.replace(',', '.')
        vBruto = parseFloat(vBruto)
      } catch (error) {
        vBruto = 0
      }

      let valorLiquido = vBruto - valorPis - valorCofins - valorIr - valorCsll - valorIss
      auxValues['valor_liquido'] = mMoeda(valorLiquido.toFixed(2))
      setData(auxValues)
    }

    fetchData()
  }, [data.valor_pis, data.valor_cofins, data.valor_csll, data.valor_ir, data.valor_iss])




  function consistData(data) {
    let required = [
      'fk_tom',
      'fk_tnf',
      'data_emissao',
      'valor_bruto',
      'descricao'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr

      if (curr === "fk_tom") fieldName = "Tomador";
      if (curr === "fk_tnf") fieldName = "Tipo de Nota";
      if (curr === "data_emissao") fieldName = "Data de Emissão";
      if (curr === "valor_bruto") fieldName = "Valor Bruto";
      if (curr === "descricao") fieldName = "Descrição do Serviço";


      if (!data[curr]) return errors.push(fieldName)
    })

    if (erroVencimento)
      errors.push('Data Vencimento inválida!')

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Confirma emissão da nota?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)

        auxValues['data_emissao'] = dateSql(auxValues['data_emissao'])
        auxValues['fk_usu'] = getUserCode()
        auxValues['pks'] = query.pks

        if (errors.length === 0) {
          api.post('/nota_fiscal/add', auxValues).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Nota fiscal emitida',
                text: "Nota fiscal emitida com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  window.location.href = "/primavera/nota_fiscal?pk_ntf=" + r.data

                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "Nota fiscal não foi emitida!",
                icon: 'warning',
              })
            }
          })
        }
        else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }


      }
    })
  }


  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }


  useEffect(() => {
    const fetchCad = async () => {

      setComboTom(await api.getComboData('tomador'))

      const auxValues = { ...data };

      if (tom > 0) {
        auxValues['fk_tom'] = tom;

        setData(auxValues);
      }
    }
    fetchCad()
  }, [tom])


  const handleChange = async (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {

      case 'valor_pis':
      case 'valor_cofins':
      case 'valor_ir':
      case 'valor_csll':
      case 'valor_iss':
        auxValues[e.target.name] = mMoeda(e.target.value);
        break;


      case 'fk_tom':
      case 'tom': {
        auxValues['fk_tom'] = e.target.value;
        setTom(e.target.value)
        break


      }
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>Emissão de Nota</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>


          <FrTextInput
            maxLength='150'
            value={nomePac || ''}
            color='#c0c0c0'
            label='Residente'
            variant='outlined'
            size='small'
            style={{ width: 350 }}
          // disabled
          />
          <br />
          <div class="container-fluid">
            <div class="row" >

              <div class="column">

                <FrSelect
                  value={data.fk_tom || ''}
                  onChange={handleChange}
                  name="fk_tom"
                  id="fk_tom"
                  label='Tomador'
                  data={comboTom}
                  style={{ width: 350 }}
                />
              </div>
              <div class="column" >
                <FrameF2 tipoModal={'Tomador'} data={data} cad={tom} setCad={setTom} ></FrameF2>
              </div>
            </div>
          </div>


          <FrDatePicker
            value={(data.data_emissao || "").split("T")[0]}
            onChange={handleChange}
            name="data_emissao"
            id="data_emissao"
            color="#528b46"
            label="Data Emissão"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
          />

          <FrDatePicker
            value={(data.data_vencimento || "").split("T")[0]}
            onChange={handleChange}
            name="data_vencimento"
            id="data_vencimento"
            color="#528b46"
            label="Data Vencimento"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
            erro={erroVencimento}
            onBlur={handleVencimento}
          />

          <FrSelect
            value={data.fk_tnf || ''}
            onChange={handleChange}
            name="fk_tnf"
            id="fk_tnf"
            label='Tipo de Nota'
            data={comboTnf}
            style={{ width: 300 }}
          />

          <br />
          <FrTextInput
            maxLength='150'
            value={data.valor_bruto || ''}
            name='valor_bruto'
            id='valor_bruto'
            color='#c0c0c0'
            label='Valor Bruto'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />

          <FrTextInput
            maxLength='150'
            value={data.valor_iss || ''}
            onChange={handleChange}
            name='valor_iss'
            id='valor_iss'
            color='#c0c0c0'
            label='Retenção ISSQN'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />

          <FrTextInput
            maxLength='150'
            value={data.valor_ir || ''}
            onChange={handleChange}
            name='valor_ir'
            id='valor_ir'
            color='#c0c0c0'
            label='Retenção IRRF'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />

          <FrTextInput
            maxLength='150'
            value={data.valor_pis || ''}
            onChange={handleChange}
            name='valor_pis'
            id='valor_pis'
            color='#c0c0c0'
            label='Retenção PIS'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />


          <FrTextInput
            maxLength='150'
            value={data.valor_cofins || ''}
            onChange={handleChange}
            name='valor_cofins'
            id='valor_cofins'
            color='#c0c0c0'
            label='Retenção COFINS'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />


          <FrTextInput
            maxLength='150'
            value={data.valor_csll || ''}
            onChange={handleChange}
            name='valor_csll'
            id='valor_csll'
            color='#c0c0c0'
            label='Retenção CSLL'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />

          <FrTextInput
            maxLength='150'
            value={data.valor_liquido || ''}
            name='valor_liquido'
            id='valor_liquido'
            color='#c0c0c0'
            label='Valor Líquido'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />


          <br />
          <FrTextInput
            maxLength='500'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            color="#528b46"
            label="Descrição do Serviço"
            variant="filled"
            size="small"
            style={{ width: 530 }}
            multiline
            rows="8"
            rowsMax="8"
            fullWidth
          />

          <br />
          <FrTextInput
            maxLength='500'
            value={data.observacao || ''}
            onChange={handleChange}
            name="observacao"
            id="observacao"
            color="#528b46"
            label="Observações"
            variant="filled"
            size="small"
            style={{ width: 530 }}
            multiline
            rows="8"
            rowsMax="8"
            fullWidth
          />

        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Confirmar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default EmissaoNotas;
