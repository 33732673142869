import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker';

import api from '../services/api'
import { dateSql, paramsToObject, getUserCode } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'

const swal = withReactContent(Swal)

function Contas_Receber() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboFpg, setComboFpg] = useState([{ value: '', display: '' }])
  const [comboCbc, setComboCbc] = useState([{ value: '', display: '' }])

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      setComboFpg(await api.getComboData('formas_pagamento'))
      setComboCbc(await api.getComboData('contas_bancarias'))

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Contas a Receber', 'Alteracao'))

        setUpdateType(true)

        api.get('/contas_receber?pk_ctr=' + query.codigo).then(async r => {
          if (r.data[0]) {
            r.data[0].valor = Number(r.data[0].valor).toFixed(2)
            r.data[0].valor_liquidado = r.data[0].format_pendente
            r.data[0].data_liquidacao = r.data[0].current_date
            r.data[0].observacao = '';

            setData(r.data[0])

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])


  function consistData(data) {
    let required = [
      'fk_fpg',
      'fk_cbc',
      'data_liquidacao',
      'valor_liquidado',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr

      if (curr === "fk_fpg") fieldName = "Forma de Pagamento";
      if (curr === "fk_cbc") fieldName = "Conta Bancária";
      if (curr === "valor_liquidado") fieldName = "Valor Liquidado";
      if (curr === "data_liquidacao") fieldName = "Data de Liquidação";


      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja liquidar o título?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues['data_liquidacao'] = dateSql(auxValues['data_liquidacao'])
        auxValues['fk_usu'] = getUserCode()
        // console.log(errors.length)
        if (errors.length === 0) {
          api.post('/contas_receber/liquidacao', auxValues).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Registro Liquidado',
                text: "O registro foi liquidado com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi liquidado!",
                icon: 'warning',
              })
            }
          })
        }
        else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }


      }
    })
  }


  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = async (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      // case 'fk_cbc':
      //   if (e.target.value > 0) {
      //     api.get('/contas_bancarias?pk_cbc=' + e.target.value).then(r => {
      //       auxValues['conta'] = r.data[0].conta;
      //       auxValues['digito'] = r.data[0].dig_conta;

      //     })
      //   }
      //   auxValues[e.target.name] = e.target.value;
      //   break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  function handleCbc(e) {
    e.preventDefault();
    if (e.target.value) {
      const auxValues = { ...data };

      api.get('/contas_bancarias?pk_cbc=' + e.target.value).then(r => {
        auxValues['conta'] = r.data[0].conta;
        auxValues['digito'] = r.data[0].dig_conta;
        setData(auxValues)

      })

    }
  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>Liquidação de Conta a Receber</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='100'
            value={data.nomepac || ''}
            onChange={handleChange}
            name='nomecad'
            id='nomecad'
            color='#c0c0c0'
            label='Residente'
            variant='outlined'
            size='small'
            style={{ width: 400 }}
            disabled
          />
          <br />


          <FrDatePicker
            value={(data.data_vencimento || "").split("T")[0]}
            onChange={handleChange}
            name="data_vencimento"
            id="data_vencimento"
            color="#528b46"
            label="Data Vencimento"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
            disabled
          />

          <FrTextInput
            maxLength='150'
            value={data.valor_total || ''}
            onChange={handleChange}
            name='valor'
            id='valor'
            color='#c0c0c0'
            label='Valor'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
            disabled
          />

          <FrTextInput
            maxLength='150'
            value={data.format_pendente || ''}
            onChange={handleChange}
            name='valor'
            id='valor'
            color='#c0c0c0'
            label='Valor Pendente'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
            disabled
          />


          <hr width="100%" size="50" color=" #528b46" />
          <br />


          <FrSelect
            value={data.fk_fpg || ''}
            onChange={handleChange}
            name="fk_fpg"
            id="fk_fpg"
            label='Forma de Pagamento'
            data={comboFpg}
            style={{ width: 300 }}
          />

          <FrSelect
            value={data.fk_cbc || ''}
            onChange={handleChange}
            name="fk_cbc"
            id="fk_cbc"
            label='Conta Bancária'
            data={comboCbc}
            style={{ width: 300 }}
            onBlur={handleCbc}
          />

          <FrTextInput
            maxLength='150'
            value={data.conta || ''}
            onChange={handleChange}
            name='conta'
            id='conta'
            color='#c0c0c0'
            label='Conta'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
            disabled
          />

          <FrTextInput
            maxLength='150'
            value={data.digito || ''}
            onChange={handleChange}
            name='digito'
            id='digito'
            color='#c0c0c0'
            label='Dígito'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
            disabled
          />
          <br />

          <FrTextInput
            maxLength='150'
            value={data.valor_liquidado || ''}
            onChange={handleChange}
            name='valor_liquidado'
            id='valor_liquidado'
            color='#c0c0c0'
            label='Valor'
            variant='outlined'
            size='small'
            style={{ width: 120 }}
          />

          <FrDatePicker
            value={(data.data_liquidacao || "").split("T")[0]}
            onChange={handleChange}
            name="data_liquidacao"
            id="data_liquidacao"
            color="#528b46"
            label="Data Liquidação"
            variant="outlined"
            size="small"
            style={{ width: 190 }}
          />

          <br />
          <FrTextInput
            maxLength='500'
            value={data.observacao || ''}
            onChange={handleChange}
            name="observacao"
            id="observacao"
            color="#528b46"
            label="Observações"
            variant="filled"
            size="small"
            style={{ width: 530 }}
            multiline
            rows="8"
            rowsMax="8"
            fullWidth
          />



        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Contas_Receber;
