import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTableReg from '../components/FrTableReg'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function MostraAlertasEstoques() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [auth, setAuth] = useState(JSON.parse(localStorage['authPrimavera'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));

  const columns = [
    { id: 'nomematmed', numeric: false, label: 'Material/Medicamento', align: 'left', minWidth: 50 },
    { id: 'nomeuni', numeric: false, label: 'UN', align: 'left', minWidth: 50 },
    { id: 'nomepac', numeric: false, label: 'Residente', align: 'left', minWidth: 50 },
    { id: 'saldo_estoque', numeric: false, label: 'Saldo Estoque', align: 'left', minWidth: 50 },
    { id: 'estoque_minimo', numeric: false, label: 'Estoque Mínimo', align: 'left', minWidth: 50 },


  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      swal.close()
      let event = new Event('build');
      getData(event)
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    setFiltered(true)
    const urlParams = new URLSearchParams(window.location.search);
    let query = paramsToObject(urlParams)
    setFilter(query)

    api.get('/estoques/mostraAlertasEstoques?fk_usu=' + auth.user).then(r => {
      setData(r.data)
      swal.close()
    })
  }

  function handleClose(e) {
    e.preventDefault();
    window.history.back()
  }

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Alertas de Estoques abaixo do mínimo</h3>
          <UserOptions />
        </div>
        <div className="tableDiv">
          <FrTableReg regName="evolucao" readonly={true} columns={columns} searched={filtered} codeName="pk_evo" page={'Evolucao'} data={data} enableEdit={false} enableDelete={false} />
        </div>

        <div className="regButtonsDiv">
          <button onClick={handleClose}>Sair</button>
        </div>

        <FooterDelphus />
      </div>
    </div>
  );
}

export default MostraAlertasEstoques;
