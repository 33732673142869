import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import FrLoading from '../FrLoading'
import api from '../../services/api'
import FrTableReg from '../FrTableReg'
import './index.css';
import TableItensEvolucao from '../TableItensEvolucao'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 15,
    marginLeft: 0,
    minWidth: 345,
    '&:first-child': {
      marginLeft: 15,
    },
  }
})

function TableDetail(props) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const classes = useStyles();

  const columns = [{ id: 'display', numeric: false, label: 'Rotina / Atividade', align: 'left', minWidth: 250 },
  { id: 'nome_dia_semana', numeric: false, label: 'Dia da Semana', align: 'left', minWidth: 150 },
  { id: 'hora_inicio', numeric: false, label: 'Hora Início', align: 'left', minWidth: 150 },
  { id: 'intervalo', numeric: false, label: 'Duração', align: 'left', minWidth: 150 }

  ]

  const columnsNivel = [{ id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 250 },
  ]

  let history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      if (props.isOpen) {
        setLoading(true)
        if (props.nivel == 2) {
          api.get('/itens_evolucao/nivel2?pk_ni1=' + props.detail).then(r => {
            console.log(r.data)
            setData(r.data)
            setLoading(false)
          })
        } else
          if (props.nivel == 3) {
            api.get('/itens_evolucao/nivel3?pk_ni2=' + props.detail).then(r => {
              console.log(r.data)
              setData(r.data)
              setLoading(false)
            })
          } else {
            api.get('/plano_trabalho/plr?pk_plt=' + props.detail).then(r => {
              console.log(r.data)
              setData(r.data)
              setLoading(false)
            })
          }
      }
    }

    fetchData()
  }, [props.isOpen])

  if (loading) {
    return (
      <>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box margin={3} >
              <FrLoading text="Buscando dados..." />
            </Box>
          </Collapse>
        </TableCell>
      </>
    )
  } else if (data.length > 0) {
    return (
      <>

        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>

            {(props.nivel == 2) ? <TableItensEvolucao nivel={2} detail={true} regName="itens_evolucao/nivel2" columns={columnsNivel} searched={false} codeName="pk_ni2" page={'RegNivel2'} data={data} enableEdit={'S'} enableDel={'S'} />
              :
              <TableItensEvolucao nivel={3} regName="itens_evolucao/nivel3" columns={columnsNivel} searched={false} codeName="pk_ni3" page={'RegNivel3'} data={data} enableEdit={'S'} enableDel={'S'} />}

          </Collapse>
        </TableCell>
      </>
    )
  } else {
    return (
      <>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box margin={3}>
              Não há registros para detalhar
            </Box>
          </Collapse>
        </TableCell>
      </>
    )
  }

}

export default TableDetail;