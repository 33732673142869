import React, { useEffect, useState } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import FrTableReg from '../components/FrTableReg'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import FrLoading from '../components/FrLoading'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, isEmpty } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Profissional(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [comboTrp, setComboTrp] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ value: '', display: '' }])
  const [comboCop, setComboCop] = useState([{ value: '', display: '' }])


  const columns = [
    { id: 'nome', numeric: false, label: 'Nome', align: 'left', minWidth: 250 },
    { id: 'nometrp', numeric: false, label: 'Tipo de Profissional', align: 'left', minWidth: 200 },
    { id: 'nomecop', numeric: false, label: 'Conselho Profissional', align: 'left', minWidth: 150 },
    { id: 'matricula', numeric: false, label: 'Matrícula', align: 'left', minWidth: 100 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboAtivo([
        {
          "value": "S",
          "display": "Ativo",
        },
        {
          "value": "N",
          "display": "Inativo",
        },])

      setComboTrp(await api.getComboData('tipo_profissional'))
      setComboCop(await api.getComboData('conselho_profissional'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)

    if (1 === 1) {
      queryObject.buscar = true
      setFiltered(true)
      // window.history.replaceState({ filtered: true }, 'filter', "/primavera/pacientes?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/profissionais', { params: filter }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    // window.history.replaceState({ filtered: false }, 'filter', '/primavera/pacientes');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <div className="ManutContentModal">
        <div className="defaultHeader">
          <h3>Profissionais</h3>
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                value={filter.nome || ''}
                style={{ width: 330 }}
                name="nome"
                id="nome"
                color="#528b46"
                label="Nome"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

              <FrSelect
                value={filter.ativo || ''}
                style={{ width: 100 }}
                name="ativo"
                id="ativo"
                label='Situação'
                data={comboAtivo}
                onChange={handleChange}
              />
              <FrSelect
                value={filter.pk_trp || ''}
                style={{ width: 200 }}
                name="pk_trp"
                id="pk_trp"
                label='Tipo de Profissional'
                data={comboTrp}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.pk_cop || ''}
                style={{ width: 160 }}
                name="pk_cop"
                id="pk_cop"
                label='Conselho Profissional'
                data={comboCop}
                onChange={handleChange}
              />

              <FrTextInput
                value={filter.matricula || ''}
                style={{ width: 90 }}
                name="matricula"
                id="matricula"
                color="#528b46"
                label="Matrícula"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTableReg readonly={true} prf={props.prf} setPrf={props.setPrf} regName="" columns={columns} searched={filtered} codeName="pk_prf" page={''} data={data} enableEdit={false} enableDel={false} />
        </div>
      </div>
    </div>
  );
}

export default Profissional;
