import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const swal = withReactContent(Swal)

class ProtectedRoute extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            auth: JSON.parse(localStorage['authPrimavera'] || JSON.stringify({ authenticated: false, user: 0 }))
        };

    }

    componentWillMount() {
        let strAuth = localStorage['authPrimavera'] || JSON.stringify({ authenticated: false, user: 0 })
        let auth = JSON.parse(strAuth)

        if (auth.last_activity) {
            let inactivityTime = Math.abs((new Date(auth.last_activity) - new Date()))
            let inactivityTimeInMinutes = inactivityTime / 60000
            // console.log(inactivityTimeInMinutes)
            if (false) {//desablitado em 15/09/21 if (inactivityTimeInMinutes >= 30) {
                localStorage['authPrimavera'] = JSON.stringify({ authenticated: false, user: 0 })
                this.setState({ auth: { authenticated: false, user: 0 } })
                swal.fire({
                    title: 'Desconectado por inatividade',
                    text: "Sessão expirada. Por favor, faça o login novamente.",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                })
            } else {
                auth.last_activity = new Date()
                localStorage['authPrimavera'] = JSON.stringify(auth)
            }
        } else {
            this.setState({ auth: { authenticated: false, user: 0 } })
            localStorage['authPrimavera'] = JSON.stringify({ authenticated: false, user: 0 })
            window.location.href = '/primavera/login'
        }
    }

    render() {
        const { component: Component, ...props } = this.props
        return (
            <Route
                {...props}
                render={props => (
                    this.state.auth.authenticated ?
                        <Component {...props} /> :
                        <Redirect to='/primavera/login' />
                )}
            />
        )
    }
}

export default ProtectedRoute;