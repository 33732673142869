import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import TableItensEvolucao from '../components/TableItensEvolucao'
import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrTableReg from '../components/FrTableReg'
import FrMultipleSelect from '../components/FrMultipleSelect'

const swal = withReactContent(Swal)

function Itens_Evolucao() {
  const [data, setData] = useState({})
  const [dataI, setDataI] = useState([])
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [dataPho, setDataPho] = useState([{ value: '', display: '' }])
  const [comboPho, setComboPho] = useState([{ value: '', display: '' }])

  const columnsPho = [{ id: 'display', numeric: false, label: 'Plano de Hospedagem', align: 'left', minWidth: 300 }]

  const columns = [
    { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Itens de Evolução', 'Alteracao'))

      } else {
        setEnableEdit(await api.getAcessos('Itens de Evolução', 'Inclusao'))

      }

      setComboPho(await api.getComboData('plano_hospedagem'))

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/itens_evolucao?pk_ni1=' + query.codigo).then(r => {
          if (r.data) {
            setData(r.data[0])
            setDataI(r.data)
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }
    fetchData()
  }, [])



  function consistData(data) {
    let required = [
      'descricao'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        swal.fire({
          title: 'Registro Incluído',
          text: "O registro foi incluído com sucesso!",
          icon: 'success',
        }).then((result) => {
          if (result.value) {
            window.history.back()
          }
        })
      }
    })
  }

  async function handleSubmit2(e) {
    e.preventDefault();
    const auxValues = { ...data };
    auxValues.dataPho = dataPho
    api.post('/itens_evolucao/add', auxValues).then(r => {
      window.history.replaceState({ filtered: true }, 'filter', "/primavera/itens_evolucao/novo?codigo=" + r.data);

      api.get('/itens_evolucao?pk_ni1=' + r.data).then(r => {
        if (r.data) {
          setDataI(r.data)
          setUpdateType(true)
        }
      })
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fk_pho':
        setDataPho(e.target.value);
        auxValues[e.target.name] = e.target.value;
        break


      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Item de Evolução</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='50'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            color="#528b46"
            label="Descrição Nível 1"
            variant="outlined"
            size="small"
            required
            style={{ width: 330 }}
            hidden={updateType}
          />

          <FrTextInput
            maxLength='3'
            value={data.ordem || ''}
            onChange={handleChange}
            name="ordem"
            id="ordem"
            color="#528b46"
            label="Ordenação"
            variant="outlined"
            size="small"
            required
            style={{ width: 100 }}
            hidden={updateType}
          />

          <FrMultipleSelect
            value={data.fk_pho || []}
            style={{ width: 500 }}
            name="fk_pho"
            id="fk_pho"
            label= {updateType ? '' : 'Planos de Hospedagem'}
            data={comboPho}
            onChange={handleChange}
            hidden={updateType}
          />


          {/* <div className="tableDiv">
            <FrTableReg readonly={true} id="gridPho" detail regName="" columns={columnsPho} searched={false} codeName="display" page={''} data={dataPho} gridName="gridPho" />
          </div> */}

          <button hidden={updateType} className="incluiFileButton" onClick={handleSubmit2}>Incluir</button>

          <div className="tableDiv">
            <TableItensEvolucao nivel={1} detail={true} regName="itens_evolucao" columns={columns} searched={false} codeName="pk_ni1" page={'Itens_Evolucao'} data={dataI} enableEdit={true} enableDel={true} />
          </div>

        </form>
        <div className="regButtonsDiv">
          <button onClick={handleCancel}>Fechar</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Itens_Evolucao;
