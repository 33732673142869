import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker';
import FrameF2 from '../components/FrameF2'
import FrTableReg from '../components/FrTableReg'

import api from '../services/api'
import { dateSql, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'

const swal = withReactContent(Swal)

function Contas_Pagar() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboFcb, setComboFcb] = useState([{ value: '', display: '' }])
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [pac, setPac] = useState('')
  const [comboIcb, setComboIcb] = useState([{ value: '', display: '' }])
  const [dataIcb, setDataIcb] = useState([{ value: '', display: '' }])
  const [residente, setResidente] = useState(false)

  const columns = [
    { id: 'format_data_emissao', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 100 },
    { id: 'display', numeric: false, label: 'Descrição', align: 'left', minWidth: 200 },
    { id: 'format_valor', numeric: true, label: 'Valor', align: 'left', minWidth: 80 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      let auth = JSON.parse(localStorage['authPrimavera']);
      setResidente(auth.residente > 0)
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      setComboFcb(await api.getComboData('forma_cobranca'))
      setComboPac(await api.getComboData('pacientes', 'reg'))

      setEnableEdit(await api.getAcessos('Contas a Receber', 'Alteracao'))

      if (query.codigo > 0) {
        setUpdateType(true)
        await api.get('/itens_cobranca?fk_ctr=' + query.codigo).then(r => {
          if (r.data) {
            setDataIcb(r.data)
          }

        })

        api.get('/contas_receber?pk_ctr=' + query.codigo).then(async r => {
          if (r.data[0]) {
            setComboPac(await api.getComboData('pacientes', 'pac' + r.data[0].fk_pac))
            r.data[0].valor_total = Number(r.data[0].valor_total).toFixed(2)
            setData(r.data[0])
            setPac(r.data[0].fk_pac)
            setComboIcb(await api.getComboData('itens_cobranca', r.data[0].fk_pac))
            if (r.data[0].valor_liquidado > 0)
              setEnableEdit(false);

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])


  function incluiIcb(e) {
    e.preventDefault();
    const auxValues = { ...data };

    if (dataIcb[0].display === '') {
      setDataIcb([{
        value: auxValues['fk_icb'],
        display: auxValues['descricao'],
        format_data_emissao: auxValues['format_data_emissao'],
        format_valor: auxValues['format_valor'],
        valor: auxValues['valor'],


      }])

    } else {

      let item = [{
        value: dataIcb[0].value,
        display: dataIcb[0].display,
        format_data_emissao: dataIcb[0].format_data_emissao,
        format_valor: dataIcb[0].format_valor,
        valor: dataIcb[0].valor,

      }]
      var i;
      for (i = 1; i < dataIcb.length; i++) {
        item.push({
          value: dataIcb[i].value,
          display: dataIcb[i].display,
          format_data_emissao: dataIcb[i].format_data_emissao,
          format_valor: dataIcb[i].format_valor,
          valor: dataIcb[i].valor,

        })
      }
      item.push({
        value: auxValues['fk_icb'],
        display: auxValues['descricao'],
        format_data_emissao: auxValues['format_data_emissao'],
        format_valor: auxValues['format_valor'],
        valor: auxValues['valor'],

      })
      setDataIcb(item)

      let total = 0;
      for (i = 0; i < item.length; i++) {
        total = total + parseFloat(item[i].valor)
      }
      console.log(total)
      auxValues['valor_total'] = Number(total).toFixed(2)
      setData(auxValues)

    }

  }

  function consistData(data) {
    let required = [
      'data_vencimento',
      'fk_pac',
      'data_emissao',
      'fk_fcb',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr

      if (curr === "fk_pac") fieldName = "Residente";
      if (curr === "data_emissao") fieldName = "Data de Emissão";
      if (curr === "fk_fcb") fieldName = "Forma de Cobrança";
      if (curr === "data_vencimento") fieldName = "Data de Vencimento";


      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues['data_emissao'] = dateSql(auxValues['data_emissao'])
        auxValues['data_vencimento'] = dateSql(auxValues['data_vencimento'])
        auxValues['fk_icb'] = dataIcb;
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/contas_receber/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/contas_receber/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    if (residente) window.history.back()
    else {
      swal.fire({
        title: 'ATENÇÃO',
        text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          window.history.back()
        }
      })
    }
  }

  useEffect(() => {
    const fetchPac = async () => {

      setComboPac(await api.getComboData('pacientes', 'pac' + pac))


      const auxValues = { ...data };

      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setData(auxValues);

      }

    }
    fetchPac()
  }, [pac])


  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fk_pac':
      case 'pac':
        auxValues['fk_pac'] = e.target.value;
        setPac(e.target.value)
        break

      case 'fk_icb':
        if (e.target.value > 0) {
          api.get('/itens_cobranca?pk_icb=' + e.target.value).then(r => {
            auxValues['descricao'] = r.data[0].descricao;
            auxValues['format_data_emissao'] = r.data[0].format_data_emissao;
            auxValues['format_valor'] = r.data[0].format_valor;
            auxValues['valor'] = r.data[0].valor;


          })
        }
        auxValues[e.target.name] = e.target.value;
        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className={"defaultReg" + (residente ? '-app' : '')}>
      <NavBar />
      <div className={"defaultRegContent" + (residente ? '-app' : '')}>
        <div className={"defaultHeader" + (residente ? '-app' : '')}>
          {residente ? (<></>) : (<h3>{updateType ? 'Edição' : 'Inclusão'} de Conta a Receber</h3>)}
          <UserOptions app={'Financeiro'} />
        </div>
        {residente ? (<></>) : (<br />)}
        <form id="regForm" onSubmit={handleSubmit} className={residente ? 'reg-app' : ''}>

          {residente ? (<></>) : (
            <div class="container-fluid">
              <div class="row" >

                <div class="column">
                  <FrSelect
                    value={data.fk_pac || ''}
                    onChange={handleChange}
                    name="fk_pac"
                    id="fk_pac"
                    data={comboPac}
                    style={{ width: (residente ? '100%' : 450), flex: (residente ? '1' : '') }}
                    required
                    disabled
                    hidden={residente}
                  />
                </div>
                {/* <div class="column" > */}

                {/* <FrameF2 tipoModal={'Residente'} data={data} pac={pac} setPac={setPac} ></FrameF2> */}
                {/* </div> */}

              </div>
            </div>
          )}

          <div style={{ display: (residente ? 'flex' : 'block'), flex: (residente ? '1' : 'block'), flexDirection: (residente ? 'column' : '') }}>
            {residente ? (
              <FrSelect
                value={data.fk_pac || ''}
                onChange={handleChange}
                name="fk_pac"
                id="fk_pac"
                data={comboPac}
                style={{ width: (residente ? '100%' : 450), flex: (residente ? '1' : '') }}
                required
                disabled
                app={residente}
              />
            ) : (<></>)
            }

            <FrDatePicker
              value={(data.data_emissao || "").split("T")[0]}
              onChange={handleChange}
              name="data_emissao"
              id="data_emissao"
              color="#528b46"
              label="Data Emissão"
              variant="outlined"
              size="small"
              disabled
              style={{ width: (residente ? '100%' : 180), flex: (residente ? '1' : '') }}
            />

            <FrDatePicker
              value={(data.data_vencimento || "").split("T")[0]}
              onChange={handleChange}
              name="data_vencimento"
              id="data_vencimento"
              color="#528b46"
              label="Data Vencimento"
              variant="outlined"
              size="small"
              style={{ width: (residente ? '100%' : 180), flex: (residente ? '1' : '') }}
              disabled={residente}
            />

            <FrSelect
              value={data.fk_fcb || ''}
              onChange={handleChange}
              name="fk_fcb"
              id="fk_fcb"
              label='Forma de Cobrança'
              data={comboFcb}
              style={{ width: (residente ? '100%' : 170), flex: (residente ? '1' : '') }}
              disabled={residente}
              app={residente}
            />
            {residente ? (<></>) : (<br />)}

            <FrTextInput
              maxLength='150'
              value={data.valor_total || ''}
              onChange={handleChange}
              name='valor_total'
              id='valor_total'
              color='#c0c0c0'
              label='Valor'
              variant='outlined'
              size='small'
              disabled
              style={{ width: (residente ? '100%' : 120), flex: (residente ? '1' : '') }}
            />


            <FrDatePicker
              value={(data.liquidacao || "").split("T")[0]}
              onChange={handleChange}
              name="data_liquidacao"
              id="data_liquidacao"
              color="#528b46"
              label="Data Liquidação"
              variant="outlined"
              size="small"
              disabled
              style={{ width: (residente ? '100%' : 190), flex: (residente ? '1' : '') }}
            />

            <FrTextInput
              maxLength='150'
              value={data.valor_liquidado || ''}
              onChange={handleChange}
              name='valor_liquidado'
              id='valor_liquidado'
              color='#c0c0c0'
              label='Valor Liquidado'
              variant='outlined'
              size='small'
              disabled
              style={{ width: (residente ? '100%' : 120), flex: (residente ? '1' : '') }}
            />

            {residente ? (<></>) : (<br />)}
            <FrTextInput
              maxLength='500'
              value={data.observacoes || ''}
              onChange={handleChange}
              name="observacoes"
              id="observacoes"
              color="#528b46"
              label="Observações"
              variant="filled"
              size="small"
              style={{ width: (residente ? '100%' : 530), flex: (residente ? '1' : '') }}
              multiline
              rows="8"
              rowsMax="8"
              fullWidth
              disabled={residente}
            />
          </div>

          <div hidden={enableEdit !== 'S'}>

            <hr width="100%" size="50" color=" #528b46" />


            <FrSelect
              value={data.fk_icb || ''}
              onChange={handleChange}
              name="fk_icb"
              id="fk_icb"
              label='Item Cobrança'
              data={comboIcb}
              style={{ width: 450 }}
            />
            <button className="incluiFileButton" onClick={incluiIcb}> {'Incluir'} </button>
            <br />
          </div>

          <div className="tableDiv">
            <FrTableReg readonly={true} detail regName="" columns={columns} searched={false} codeName="pk_icb" page={''} data={dataIcb} />
          </div>


        </form>
        <div className={"regButtonsDiv" + (residente ? '-app' : '')}>
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Contas_Pagar;
